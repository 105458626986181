import { render, staticRenderFns } from "./PubListDocuments.vue?vue&type=template&id=5f62f473&"
import script from "./PubListDocuments.vue?vue&type=script&lang=js&"
export * from "./PubListDocuments.vue?vue&type=script&lang=js&"
import style0 from "./PubListDocuments.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports