<template>
  <div class="list-group personal-list-group">
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <a
      v-else
      v-for="i in items"
      :key="i.key"
      :href="i.url"
      target="_blank"
      class="list-group-item list-group-item-action"
    >
      <strong v-if="i.anio">({{ i.anio }})</strong> {{ i.titulo }}
    </a>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["docto"],
  name: "PubListDocuments",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("anio", "desc").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          titulo: doc.data().titulo,
          url: doc.data().url,
          anio: doc.data().anio,
        });
      });
      //console.log(this.items);
    });
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.personal-list-group {
  border-radius: 0;

  a {
    font-size: 0.85rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }

    &:hover {
      background: $color-02;
      color: white;
    }
  }
}
</style>
